import React from "react";
import i18nextI18n from "i18next";
import {I18nNamespace} from "./i18n-namespaces";
import {createServerTranslation} from "./i18n.server";
import {Trans as TransWithoutContext} from "react-i18next/TransWithoutContext";

const defaultNS: I18nNamespace = "website";

const Trans = (props: {children: React.ReactNode; ns?: I18nNamespace}): React.ReactElement => {
  const {ns, children} = props;
  const {t} = createServerTranslation();

  return (
    <TransWithoutContext
      shouldUnescape
      i18n={i18nextI18n}
      // @ts-expect-error Type 'Translate' is not assignable to type 'Translate & TFunction<"website" | "website-db" | "web-scheduling" | "confirm-identity", undefined>'.
      t={t}
      ns={ns ?? defaultNS}
    >
      {children}
    </TransWithoutContext>
  );
};

export default Trans;
