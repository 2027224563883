import {colors} from "@c10h/colors";
import Insurances from "@components/Insurances";
import {useTranslation} from "ni18n";
import React, {useMemo} from "react";
import Hero from "src/components/v5/Home/Hero";
import {ImageGrid} from "src/components/v5/Home/ImageGrid";
import OtherProviders from "src/components/v5/OtherProviders";
import Download from "src/components/v5/Reason/Download";
import {schema} from "src/utils/schemaUtils";

import Layout from "../components/v5/Layout";
import {NavTheme} from "../components/v5/Nav/useNavState";
import Services from "../components/v5/Services";
import TestimonialSlider from "../components/v5/TestimonialSlider";
import {PracticeId} from "../constants/practiceIds";
import {useTypedSelector} from "../store";
import HomeCareDiscoverySearch from "../components/v5/Home/HomeCareDiscoverySearch";

const Index = () => {
  const i18n = useTranslation();
  const {locations} = useTypedSelector(state => state.config);
  const reasons = useTypedSelector(state => state.config.reasons) ?? [];

  // @ts-expect-error TS7006: Parameter 'd' implicitly has an 'any' type.
  const doctorFilter = d =>
    d.specialties.includes("Primary Care") && d.practiceId === PracticeId.CARBON;

  const schemas = [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      url: schema.website,
      logo: schema.logo,
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: schema.address.telephone,
          contactType: "billing support",
          areaServed: "US",
        },
      ],
    },
    {
      "@context": "http://schema.org/",
      "@type": "MedicalOrganization",
      name: "Carbon Health Primary Care",
      url: schema.website,
      address: {
        "@type": "PostalAddress",
        addressCountry: schema.address.addressCountry,
        addressLocality: schema.address.addressLocality,
        addressRegion: schema.address.addressRegion,
        postalCode: schema.address.postalCode,
        streetAddress: schema.address.streetAddress,
        email: schema.address.email,
        telephone: schema.address.telephone,
      },
      logo: {
        "@type": "ImageObject",
        image: schema.logo,
        contentUrl: schema.logo,
      },
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: schema.name,
      headline: schema.title,
      image: schema.logo,
      description: schema.description,
      publisher: {
        "@type": "Organization",
        logo: {
          "@type": "ImageObject",
          url: schema.logo,
        },
      },
    },
  ].map(s => JSON.stringify(s));

  const bookingWidget = useMemo(
    () => <HomeCareDiscoverySearch locations={locations} reasons={reasons} />,
    [locations],
  );

  return (
    <Layout
      pageTitle={i18n.t("Carbon Health | Modern Primary & Urgent Care")}
      pageDescription={i18n.t(
        "Carbon Health Provides Smart, hassle-free Primary & Urgent Care. Book same day Adult & Pediatric appointments instantly.",
      )}
      style={{
        "--brandColor": colors.mint,
        "--brandColorDarker": colors.darkerMint,
      }}
      navTheme={NavTheme.LIGHT}
      schemas={schemas}
    >
      <Hero t={i18n.t} bookingWidget={bookingWidget} />
      <ImageGrid t={i18n.t} />
      <Services locations={locations} />
      <Download trackingLabel="Home" />
      <OtherProviders
        filter={doctorFilter}
        coordinate={{latitude: 39.5501, longitude: -105.7821}}
        radius={2000}
      />
      <Insurances />
      <TestimonialSlider />
    </Layout>
  );
};

export default Index;
