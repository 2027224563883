import uniq from "lodash/uniq";
import memoizee from "memoizee";

import {useTypedSelector} from "../store";
import {useUserSelectedLocation} from "../utils/browser-storage/userSelectedLocation";

const getRegionFromLoc = (l: LocationForRegionSpecialties) => l.region.slug;

export type LocationForRegionSpecialties = {region: {slug: string}; specialtyIds: string[]};

export const buildRegionSpecialtyMap = memoizee((locations: LocationForRegionSpecialties[]) =>
  locations.reduce<{[key: string]: string[]}>((acc, next) => {
    const key = getRegionFromLoc(next);
    const prevList = acc[key] || [];
    return {
      ...acc,
      [key]: uniq([...prevList, ...next.specialtyIds]),
    };
  }, {}),
);

export const useSpecialtiesInRegion = (): string[] => {
  const locations = useTypedSelector(state => state.config.locations);
  const {regionSlug: selectedRegion} = useUserSelectedLocation();
  const map = locations && buildRegionSpecialtyMap(locations);
  return map && selectedRegion ? map[selectedRegion] : [];
};
