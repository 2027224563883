import React from "react";
import Image, {ImageProps} from "next/image";
import {Translate} from "next-translate";

type ImageItemProps = {className: string | undefined; t: Translate} & ImageItem;

const ImageItem: React.FunctionComponent<ImageItemProps> = ({
  header,
  content,
  image,
  className,
  step,
  t,
}): React.ReactElement => {
  return (
    <li className={`mb16 mb10-md ph4 ph0-md ${className}`}>
      <Image {...image} alt={image.alt ?? ""} />
      {Number.isInteger(step) && (
        <div className="darkGreen ttu font-im">{t("Step {{number}}", {number: step})}</div>
      )}
      <h2 className="font-cm fs28 fs20-md lh12 mt2 mb3 mb1-md gray800 maxw18-md">{header}</h2>
      <p className="lh14 fs16-md font-ir gray600 maxw25 maxw16-md">{content}</p>
    </li>
  );
};

export type ImageItem = {
  header: string;
  content: string;
  step?: number;
  image: Omit<ImageProps, "alt"> & Partial<Pick<ImageProps, "alt">>;
};

interface ImageItemListProps {
  items: ImageItem[];
  rows: number;
  t: Translate;
  itemClassName?: string;
  showSteps?: boolean;
}

export const ImageItemList: React.FunctionComponent<ImageItemListProps> = ({
  items,
  rows = 1,
  itemClassName = "",
  showSteps = false,
  t,
}) => (
  <ul className={`df jcsb fdc-md ${rows > 1 && "fw"}`}>
    {items.map((item, key) => (
      <ImageItem
        // @ts-expect-error TS2322: Type 'number | false' is not assignable to type 'number | undefined'.
        step={showSteps && key + 1}
        key={key}
        {...item}
        t={t}
        className={`${itemClassName} ${rows === 2 && "w50p w100p-md"}`}
      />
    ))}
  </ul>
);
