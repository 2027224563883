import {useTranslation} from "ni18n";
import React from "react";

import {SpecialtyId} from "../../constants/specialtyIds";
import {useGetSoonestInPracticeBySpecialtyId} from "../../hooks/useGetSoonestTime";
import {todayTomorrow4} from "../_common/todayTomorrow";
import {LazyComp} from "../_common/LazyComp";
import {useTypedSelector} from "../../store";

export const ReadableSlotTimeBySpecialtyId = ({
  specialtyId,
}: {
  specialtyId: SpecialtyId;
}): React.ReactElement => {
  const i18n = useTranslation();
  const {locations} = useTypedSelector(({config}) => config);
  const maybeSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId,
    locations,
  });
  const nextApptSlotTime = todayTomorrow4(i18n.t, maybeSlot?.time);

  return <LazyComp>{nextApptSlotTime}</LazyComp>;
};
