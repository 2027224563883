import {I18n} from "next-translate";
import nextUseTranslation from "next-translate/useTranslation";

import {I18nNamespace} from "./i18n-namespaces";
import {fixInterpolation} from "./i18nFixInterpolation";

// When translating new text, make sure to update the translations too:
// https://mdcollab.atlassian.net/wiki/spaces/engineering/pages/680329381/Localization
const createServerTranslation = (namespace?: I18nNamespace): I18n => {
  const i18nWithBadInterpolation = nextUseTranslation(namespace);

  return {
    t: fixInterpolation(i18nWithBadInterpolation.t),
    lang: i18nWithBadInterpolation.lang,
  };
};

export {createServerTranslation};
