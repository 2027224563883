import Image from "next/image";
import React from "react";

import ContentArea from "../../composition/ContentArea";
import Section from "../../composition/Section";
import Icon from "../Icon";
import {Translate} from "next-translate";

type Props = {
  t: Translate;
  bookingWidget: React.ReactNode;
};

const Line2 = ({children}: {children: React.ReactChild}) => (
  <p className="white text-xl lg:text-[28px] lg:leading-[34px] font-cm tracking-tight">
    {children}
  </p>
);

const Hero: React.FC<Props> = ({t, bookingWidget}) => {
  return (
    <>
      <Section className="relative pt-40 pb-20 lg:pt-40 lg:pb-20 flex flex-col bg-gray-300">
        <Image
          src="/static/img/home/mac-n-cheese.jpg"
          alt={t("A mother eating with her daughter")}
          className="mix-blend-multiply object-cover object-[left_calc(20%)_bottom_calc(50%)] lg:object-right"
          priority
          fill
        />
        <ContentArea className="flex gap-8 justify-between items-end relative">
          <div className="flex flex-col lg:mb-40">
            <h1 className="text-white maxw30 mb-4 font-cm text-3xl lg:text-5xl">
              {t("This is care\nas it should be")}
            </h1>
            <Line2>{t("Easy scheduling.")}</Line2>
            <Line2>{t("Caring providers.")}</Line2>
            <Line2>{t("In-person and in our app.")}</Line2>
          </div>
          <span className="absolute lg:bottom-0 left-0 font-ttr text-gray-50 block -bottom-8 text-sm lg:text-base">
            {t("Scroll to learn more")}
            <Icon icon="arrow-right3" className="rotate-90 inline-block ml-2 text-xs" />
          </span>
          <div className="hidden lg:block w-[434px]">
            <div className="bg-white rounded-xl p-6 flex flex-col justify-between">
              {bookingWidget}
            </div>
          </div>
        </ContentArea>
      </Section>
      <Section className="lg:hidden">
        <ContentArea className="py-6">{bookingWidget}</ContentArea>
      </Section>
    </>
  );
};

export default React.memo(Hero);
